<template>
  <!-- 视频预览 -->
  <div class="contains-video" id="monitors">
    <div class="right">
      <le-card title="场景">
        <div class="right_top">
          <el-tree :data="landscaplist" node-key="_id" ref="tree" :expand-on-click-node="false" highlight-current
            :props="defaultProps" @node-click="handleCheckChangeArea">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span class="custom-tree-node-font">
                {{ `(${treeListLenghts(data)})` }}
              </span>
            </span>
          </el-tree>
        </div>
      </le-card>
      <le-card title="设备">
        <div class="right_bottom">
          <div v-for="(item, index) in selectDeviceList" :key="index" class="right_item"
            @click="onClickDevice(item.MainId)">
            <div>
              <span> {{ item.ChannelName }} </span>
            </div>
            <div class="icon iconfont lebo-tablerscan-eye1"></div>
          </div>
        </div>
      </le-card>
    </div>
    <div class="left">
      <div class="left_top-video">
        <template v-if="selectDeviceListSort.length > 0">
          <div v-for="i in howNumber" class="rownumber" :key="i">
            <div v-for="j in howNumber" :key="j" class="columnnumber">
              <VideoCustomize v-if="howNumber !== 0 &&
                selectDeviceListSort[
                (i - 1) * howNumber + j - 1 + totolItem
                ] &&
                selectDeviceListSort[(i - 1) * howNumber + j - 1 + totolItem]
                  .mainId &&
                !isShow
                " :id="'videoElement' + ((i - 1) * howNumber + j - 1) + howNumber" :mainid="selectDeviceListSort[(i - 1) * howNumber + j - 1 + totolItem]
      ? selectDeviceListSort[
        (i - 1) * howNumber + j - 1 + totolItem
      ].mainId : ''" :height="videoheight" :fullscreenstatus="fullscreenstatus"></VideoCustomize>
              <div class="noresource" v-if="howNumber === 0 ||
                !selectDeviceListSort[
                (i - 1) * howNumber + j - 1 + totolItem
                ] ||
                !selectDeviceListSort[(i - 1) * howNumber + j - 1 + totolItem]
                  .mainId ||
                isShow
                ">
                <!-- <span> 无监控画面 </span> -->
              </div>
            </div>
          </div>
        </template>
        <div class="left_empty" v-else>暂无数据</div>
      </div>
      <div class="left_bottom">
        <div class="left_bottom_left">
          <i class="iconfont lebo-a-1" @click="showScreen(1)" :style="howNumber == 1 ? 'color:#32c5d2;' : ''"></i>
          <i class="iconfont lebo-a-2" @click="showScreen(2)" :style="howNumber == 2 ? 'color:#32c5d2;' : ''"></i>
          <i class="iconfont lebo-a-3" @click="showScreen(3)" :style="howNumber == 3 ? 'color:#32c5d2;' : ''"></i>
          <i class="iconfont lebo-a-4" @click="showScreen(4)" :style="howNumber == 4 ? 'color:#32c5d2;' : ''"></i>
          <i class="iconfont lebo-a-5" @click="showScreen(5)" :style="howNumber == 5 ? 'color:#32c5d2;' : ''"></i>
        </div>
        <div class="left_bottom_right">
          <i class="backPage" @click="upPage">
          </i>
          <span>
            {{ `第${page + 1}页/共${totalpage}页` }}
          </span>
          <i class="nextPage" @click="nextPage">
          </i>
        </div>
        <div class="left_bottom_fullScreenBox">
          <svg @click="fullScreen" t="1618370024595" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="16302">
            <path
              d="M628.053333 628.053333a32 32 0 0 1 45.226667 0l158.72 158.634667V693.333333l0.298667-4.352A32 32 0 0 1 896 693.333333v181.333334l-0.341333 3.84a21.333333 21.333333 0 0 1-20.992 17.493333h-181.333334l-4.352-0.298667a32 32 0 0 1-27.648-31.701333l0.298667-4.352a32 32 0 0 1 31.701333-27.648h93.44l-158.72-158.72-3.114666-3.584a32 32 0 0 1 3.114666-41.642667z m-232.106666 0a32 32 0 0 1 3.114666 41.642667l-3.114666 3.584-158.72 158.72h93.44a32 32 0 0 1 31.701333 27.648l0.298667 4.352a32 32 0 0 1-27.648 31.701333L330.666667 896H149.333333a21.333333 21.333333 0 0 1-20.992-17.493333L128 874.666667v-181.333334a32 32 0 0 1 63.701333-4.352l0.298667 4.352v93.354667l158.72-158.634667a32 32 0 0 1 45.226667 0zM874.666667 128a21.333333 21.333333 0 0 1 20.992 17.493333L896 149.333333v181.333334a32 32 0 0 1-63.701333 4.352L832 330.666667V237.312L673.28 395.946667a32 32 0 0 1-48.341333-41.642667l3.114666-3.584 158.72-158.72h-93.44a32 32 0 0 1-31.701333-27.648L661.333333 160a32 32 0 0 1 27.648-31.701333L693.333333 128h181.333334zM330.666667 128l4.352 0.298667a32 32 0 0 1 27.648 31.701333l-0.298667 4.352a32 32 0 0 1-31.701333 27.648H237.226667l158.72 158.72 3.114666 3.584a32 32 0 0 1-48.341333 41.642667L192 237.312V330.666667l-0.298667 4.352A32 32 0 0 1 128 330.666667V149.333333l0.341333-3.84A21.333333 21.333333 0 0 1 149.333333 128h181.333334z"
              p-id="16303" fill="#707070"></path>
          </svg>
        </div>

      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <VideoCustomize ref="videoVideoTestDialog" id="videoElements" :mainid="dialogMainId"></VideoCustomize>
    </el-dialog>
  </div>
</template>
<script>
import VideoCustomize from './components/videoCustomize/VideoCustomize.vue'
import { getBindScene, getMonitorUrlList } from '@/api/monitoring'
export default {
  components: {
    VideoCustomize
  },
  data () {
    return {
      howNumber: 0,
      page: 0,
      dialogVisible: false,
      dialogMainId: '',
      treeItemData: '',
      deviceList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      landscaplist: [], // 整个树控件的数据
      playerOptions: {
        notSupportedMessage:
          '此视频暂无法播放，检查相机状态是否正常或请查看是否安装flash',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/flv',
            src: '' // 需要播放的rtmp地址
          }
        ],
        techOrder: ['flash', 'html5'], // 兼容顺序
        html5: { hls: { withCredentials: false } },
        autoplay: true,
        muted: true,
        controls: true,
        poster: '',
        fluid: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          currentTimeDisplay: true, // 当前时间
          volumeControl: false, // 声音控制键
          playToggle: false, // 暂停和播放键
          progressControl: true, // 进度条
          fullscreenToggle: true // 全屏按钮
        }
      },
      treeSelectList: [], // 选中的场景节点
      selectDeviceList: [], // 右边下角的所有设备
      selectDeviceListSort: [], // 监控画面按照顺序排列的集合
      is_interval: false,
      interval_time: 1,
      timerInterval: null,
      isShow: false,
      videoheight: '100%',
      fullscreenstatus: false
    }
  },
  computed: {
    totalpage: function () {
      // console.log('totalpage---------', this.selectDeviceListSort.length);
      // console.log('totalpage---------', this.howNumber);
      return this.selectDeviceListSort.length > 0
        ? Math.ceil(
          this.selectDeviceListSort.length / Math.pow(this.howNumber, 2)
        )
        : 1
    },
    totolItem: function () {
      return Math.pow(this.howNumber, 2) * this.page
    }
  },
  watch: {
    dialogVisible: function (newMsg, oldMsg) {
      if (newMsg) {
        if (this.is_interval && this.timerInterval !== null) {
          clearInterval(this.timerInterval)
          this.timerInterval = null
        }
        if (this.$refs.videoVideoTestDialog) {
          this.$refs.videoVideoTestDialog.initialize()
        }
      } else {
        if (this.is_interval && this.timerInterval === null) {
          this.timerInterval = setInterval(() => {
            this.nextPage()
          }, this.interval_time * 1000)
        }
        this.$refs.videoVideoTestDialog &&
          this.$refs.videoVideoTestDialog.flv_destroy()
      }
    },
    is_interval: function (newMsg, oldMsg) {
      if (newMsg) {
        if (!this.timerInterval) {
          // console.log(
          //   "==--=this.interval_time * 1000-=-=" + this.interval_time * 1000
          // );
          this.timerInterval = setInterval(() => {
            this.nextPage()
          }, this.interval_time * 1000)
        }
      } else {
        if (this.timerInterval) {
          clearInterval(this.timerInterval)
          this.timerInterval = null
        }
      }
    },
    howNumber (newV, oldV) {
      console.log('howNumber-----', newV)
      if (newV === 1) {
        // this.videoheight = document.body.clientHeight - 155 + "px";
        this.videoheight = document.body.clientHeight - 190 + 'px'
      } else {
        this.videoheight =
          (document.body.clientHeight - 185) / newV - newV + 'px'
      }
    }
  },
  created () {
    const that = this
    window.onresize = function () {
      if (!that.checkFull()) {
        // 退出全屏后要执行的动作
        // console.log("退出全屏")
        that.fullscreenstatus = false
      } else {
        // console.log("全屏")
      }
    }
  },
  mounted () {
    // console.log("document.body.clientHeight=", document.body.clientHeight);
    // document.querySelector('.mainbody').style.paddingBottom = '3px';
    // document.querySelector('.right_box').style.height = (document.body.clientHeight - 66) + 'px';
    document.querySelector('.contains-video').style.height =
      document.body.clientHeight - 120 + 'px'
    var that = this
    this.init()
    // 监听页面的显示与隐藏
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        // console.log("===-=-visible=-=-=");
        that.isShow = false
        if (that.is_interval && that.timerInterval === null) {
          that.timerInterval = setInterval(() => {
            that.nextPage()
          }, that.interval_time * 1000)
        }
      }
      if (document.visibilityState === 'hidden') {
        // console.log("===-=-hidden=-=-=");
        if (that.is_interval && that.timerInterval !== null) {
          clearInterval(that.timerInterval)
          that.timerInterval = null
        }
        that.isShow = true
      }
    })
  },
  methods: {
    checkFull () {
      // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull
    },
    fullScreen () {
      if (this.selectDeviceListSort.length <= 0) return
      this.fullscreenstatus = true
      var de = document.querySelector('.left_top-video')
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
    },
    showScreen (val) {
      if (this.selectDeviceListSort.length <= 0) return
      this.howNumber = 0
      setTimeout(() => {
        this.reset()
        this.howNumber = val
      }, 50)
    },
    // oneScreen() {
    //   this.howNumber = 0;
    //   setTimeout(() => {
    //     this.reset();
    //     this.howNumber = 1;
    //   }, 50);
    // },
    // fourScreen() {
    //   this.howNumber = 0;
    //   setTimeout(() => {
    //     this.reset();
    //     this.howNumber = 2;
    //   }, 50);
    // },
    // nineScreen() {
    //   this.howNumber = 0;
    //   setTimeout(() => {
    //     this.reset();
    //     this.howNumber = 3;
    //   }, 50);
    // },
    reset () {
      this.page = 0
    },
    upPage () {
      if (this.selectDeviceListSort.length <= 0) return
      var that = this
      const pageSize = Math.ceil(
        this.selectDeviceListSort.length / Math.pow(this.howNumber, 2)
      )
      that.isShow = true
      if (this.page > 0) {
        setTimeout(() => {
          that.page -= 1
          that.isShow = false
        }, 50)
      } else {
        setTimeout(() => {
          that.page = pageSize - 1
          that.isShow = false
        }, 50)
      }
    },
    nextPage () {
      if (this.selectDeviceListSort.length <= 0) return
      var that = this
      const pageSize = Math.ceil(
        that.selectDeviceListSort.length / Math.pow(this.howNumber, 2)
      )
      that.isShow = true
      // console.log("this.page = " + this.page);
      if (that.page < pageSize - 1) {
        setTimeout(() => {
          that.page += 1
          that.isShow = false
        }, 50)
      } else {
        setTimeout(() => {
          that.page = 0
          that.isShow = false
        }, 50)
      }
    },
    // 当复选框被点击的时候触发
    handleCheckChangeArea (data) {
      // console.log("====--=handleCheckChangeArea=--===", JSON.stringify(data));
      this.treeItemData = data // 暂存选中节点
      this.$refs.tree.setCheckedKeys([]) // 删除所有选中节点
      this.$refs.tree.setCheckedNodes([data]) // 选中已选中节点 为保证只单选
      if ('monitor_count' in data) {
        this.selectDeviceList = []
        this.selectDeviceListSort = []
        this.getDeviceandvideoOrder(data, null, true)
        return
      }
      var devicesId = [data.Id]
      if (data.children.length > 0) {
        this.traverseDevicesid(devicesId, data.children)
      }
      var indexNum = this.deviceList.findIndex((item) => {
        return item.findIndex((child) => child === data.Id) > -1
      })
      if (indexNum > -1) {
        this.selectDeviceList = []
        this.selectDeviceListSort = []
        this.getDeviceandvideoOrder(
          this.landscaplist[indexNum],
          devicesId,
          false
        )
      } else {
        this.selectDeviceListSort = []
        this.selectDeviceList = []
      }
    },
    treeListLenghts (data) {
      // 如果是场景节点那么直接取monitor_info.length
      if ('monitor_info' in data) {
        return data.monitor_info.length
      }
      // 获取到当前节点下的所有Id包括自身
      var devicesChildren = [data.Id]
      if (data.children.length > 0) {
        this.traverseDevicesid(devicesChildren, data.children)
      }
      var indexNum = this.deviceList.findIndex((item) => {
        return item.findIndex((child) => child === data.Id) > -1
      })
      // 获取到当前场景下的所有设备
      var currentDevices = this.landscaplist[indexNum].monitor_info
      // 遍历当前场景下的设备查看目前有多少是在根节点上
      if (currentDevices.length === 0) return 0
      var devicesNum = currentDevices.filter(
        (item) => devicesChildren.indexOf(item.CategoryId) > -1
      )
      if (devicesNum && devicesNum.length > 0) {
        return devicesNum.length
      }
      return 0
    },
    onClickDevice (id) {
      this.dialogMainId = id
      this.dialogVisible = true
    },
    totalNum (arry, oldArry) {
      if (arry && arry.length > 0) {
        for (var i in arry) {
          oldArry.push(arry[i]._id)
          if (arry[i].children.length > 0) {
            this.totalNum(arry[i].children, oldArry)
          }
        }
      }
    },
    traverse (arry) {
      if (arry && arry.length > 0) {
        for (var i in arry) {
          this.treeSelectList.push(arry[i].Id)
          if (arry[i].children.length > 0) {
            this.traverse(arry[i].children)
          }
        }
      }
    },
    getHomNumber (num) {
      if (num === 1) {
        return 1
      } else if (num > 1) {
        return 2
      } else {
        return 2
      }
    },
    traverseDevicesid (zi, arry) {
      if (arry && arry.length > 0) {
        for (var i in arry) {
          zi.push(arry[i].Id)
          if (arry[i].children.length > 0) {
            this.traverseDevicesid(zi, arry[i].children)
          }
        }
      }
    },
    async init () {
      const res = await getBindScene()
      // console.log('=-=-getBindScene=-=-res=', JSON.stringify(res))
      if (res.Code !== 200 || !res.Data || res.Data.length <= 0) return
      this.landscaplist = res.Data
      res.Data.forEach((fu) => {
        var children = [fu.Id]
        if (fu.children.length > 0) {
          this.traverseDevicesid(children, fu.children)
        }
        this.deviceList.push(children)
      })
      if (this.landscaplist && this.landscaplist.length > 0) {
        this.landscaplist.forEach((item) => {
          this.getDeviceandvideoOrder(item, null, true)
        })
        // this.getDeviceandvideoOrder(this.landscaplist[0], null, true);
      }
      // console.log("deviceList=-=-=-=-=-=====", JSON.stringify(this.deviceList));
    },
    // 遍历获取右下角的设备和左边视频的播放地址。
    getDeviceandvideoOrder (currentNode, idList, isTop) {
      // currentNode 当前场景下的整条数据
      // idList 为当前节点下的所有id
      // isTop 是否为场景的最高级别节点
      // console.log('getDeviceandvideoOrder-----', currentNode);

      this.is_interval = currentNode.is_interval
      this.interval_time = currentNode.interval_time - 0
      this.page = 0
      this.howNumber = Math.sqrt(currentNode.num)
      if (isTop) {
        // 如果点击的是最上层的节点
        this.selectDeviceList = this.selectDeviceList.concat(
          currentNode.monitor_info
        )
        this.selectDeviceListSort = this.selectDeviceListSort.concat(
          currentNode.monitor_list
        )
        // console.log("this.selectDeviceListSort========", JSON.stringify(this.selectDeviceListSort));
        return
      }
      // 如果点击的不是最上层的节点
      var t = currentNode.monitor_info.filter(
        (item) => idList.indexOf(item.CategoryId) > -1
      )
      // console.log(t);
      if (t.length > 0) {
        this.selectDeviceList = this.selectDeviceList.concat(t)
        // console.log('selectDeviceList----', this.selectDeviceList);
        this.selectDeviceListSort = this.selectDeviceListSort.concat(
          currentNode.monitor_list.filter((item) => {
            return (
              this.selectDeviceList.findIndex(
                (tem) => tem.MainId === item.mainId
              ) > -1
            )
          })
        )
      }
      // console.log("this.selectDeviceListSort========", JSON.stringify(this.selectDeviceListSort));
    }
  }
}
</script>

<style scoped lang='less'>
.contains-video {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 5px 15px 15px;

  .left_empty {
    color: #909399;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.left_top-video {
  display: flex;
  flex-direction: column;
  flex: 1;

  .rownumber {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 5px;

    .columnnumber {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-right: 5px;
    }
  }
}

.left_bottom {
  display: flex;
  flex-direction: row;
  height: 45px;
  padding: 0 1px;
  justify-content: space-between;
  align-items: center;
}

.left_bottom_left {
  margin-left: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .iconfont {
    font-size: 40px;
    cursor: pointer;
    margin-right: 5px;
  }
}

.left_bottom_right {
  margin-right: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  vertical-align: bottom;

  .backPage {
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/img/ychj/backarrowicon.png');
    background-size: 100%;
    font-size: 26px;
    cursor: pointer;
  }

  .nextPage {
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/img/ychj/nextarrowicon.png');
    background-size: 100%;
    font-size: 26px;
    cursor: pointer;
  }

  &>span {
    color: #36c6d3;
    font-size: 18px;
    margin: 0 10px;
  }

  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

.left_bottom_left>svg {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.left_bottom_right>svg {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

.left_bottom_fullScreenBox {
  width: 35px;
  height: 35px;
}

.right {
  display: flex;
  height: 98%;
  width: 17%;
  margin-top: 15px;
  margin-left: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-direction: column;

  .lebo-card {
    height: 46%;
  }
}

.vidoclass {
  display: flex !important;
  flex: 1 !important;
  width: 33%;
}

.right_top {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 90%;
  margin-bottom: 2px;
  background-color: white;
  overflow: auto;
  .custom-tree-node-font{
    font-size: 12px;
     color: #cccccc;
  }
}

.right_title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: white;
  font-size: 20px;
}

.right_bottom {
  display: flex;
  flex: 2;
  height: 90%;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
}

.right_item {
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  margin-top: 5px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 5px;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  border-radius: 5px 5px 5px 5px;
}

.right_item>div {
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
}

.videoModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  position: inherit;
  left: 50%;
  top: 65%;
  transform: translate(-50%);
  text-align: center;
  width: auto;
  height: 40px;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 0 20px;
  border-radius: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.noresource {
  display: flex;
  flex: 1;
  // background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  background-image: url('~@/assets/img/noMessage/videoNoSetting.png');
  background-size: 20% 45%;
  background-position: center center;
  background-repeat: no-repeat;
}

.el-dialog__wrapper {
  /deep/.el-dialog {
    height: 50%;

    .el-dialog__header {
      height: 0 !important;
      padding: 0 !important;

      .el-dialog__headerbtn {
        right: 5px !important;
        top: 5px !important;
        width: 30px !important;
        height: 30px !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        border-radius: 15px !important;
        overflow: hidden !important;
        z-index: 9999 !important;
      }
    }

    .el-dialog__body {
      height: 100%;
      padding: 0 !important;
    }
  }
}

.lebo-card {
  box-shadow: unset;
}</style>

<template>
  <div class="neiyi">
    <!-- 加载中 -->
    <div v-if="loading" class="loadingBox">
      <div class="loadingSpan">设备加载中...</div>
    </div>
    <template v-else>
      <video v-if="sources !== ''" ref="videoPlayers" width="100%" :height="fullscreenstatus ? '100%' : height" :id="id"
        autoplay muted @mouseover="onMouseOver" style="object-fit: fill" @click="click" @dblclick="dblclick"
        @playing="playing"></video>
      <!-- 设备离线 -->
      <div class="replacediv" v-if="sources === ''">
      </div>
    </template>
    <!-- 遮罩栏   添加了一个父盒子展示是否显示遮罩needMask-->
    <div class="needMaskzhehzao" v-if="needMask">
      <div class="zhezhao" v-if="isOver">
        <div class="left">{{ ChannelName }}</div>
        <div class="right">
          <div class="icon iconfont lebo-uilcamera" @click="screenshot"></div>
          <div class="icon iconfont lebo-jamvideo-camera right-big" @click="recordScreen"></div>
          <div class="icon iconfont lebo-parkfull-screen2" @click="fullScreen"></div>
        </div>
      </div>
    </div>
    <div class="zhezhao" v-if="isOver">
      <div class="left">{{ ChannelName }}</div>
      <div class="right">
        <div class="icon iconfont lebo-uilcamera" @click="screenshot"></div>
        <div class="icon iconfont lebo-jamvideo-camera" @click="recordScreen"></div>
        <div class="icon iconfont lebo-parkfull-screen2" @click="fullScreen"></div>
      </div>
    </div>
    <div class="recoder" v-if="recordeFlag">
      <span>正在录制...</span>
    </div>
  </div>
</template>

<script>
import flv from 'flv.js'
import cutpicture from '@/utils/cutpicture'
import { getMonitorUrlList, startRecord, stopRecord } from '@/api/monitoring'
export default {
  //   muted
  // autoplay
  props: ['id', 'height', 'fullscreenstatus', 'mainid', 'needMask'],
  data () {
    return {
      loading: true,
      //   url: "http://vjs.zencdn.net/v/oceans.mp4",
      url: 'http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=5',
      player: null,
      isOver: false,
      timer: null,
      sources: '',
      recordeFlag: false,
      MediaServerId: '',
      ChannelName: ''
    }
  },
  watch: {
    mainid: {
      deep: true,
      handler (newurl, oldurl) {
        var videoElement = document.getElementById(this.id)
        // console.log("==-===-=newurl=" + newurl);
        // console.log("==-===-=oldurl=" + oldurl);
        // console.log("==-===-=id=" + this.id);
        if (videoElement) {
          if (!this.loading) {
            this.loading = true
          }
          if (this.player) {
            this.player.unload()
            this.player.detachMediaElement()
            this.player.destroy()
            this.player = null
          }
          this.initVedioUrl()
        }
      }
    },
    fullscreenstatus (newV, oldV) {
      // console.log("fullscreen = ", typeof newV);
    }
  },
  computed: {
    realeid: function () {
      return this.id
    }
  },
  methods: {
    async initVedioUrl () {
      var obj = {}
      obj.MainId = this.mainid
      const res = await getMonitorUrlList(obj)
      this.loading = false
      console.log('-=-=-=-getMonitorUrlList=', res)
      if (!res) return
      if (res.Code !== 200) return
      if (res.Data.length === 0 || res.Data[0].PlayUrl.length === 0) {
        this.sources = ''
        return
      }
      this.sources = res.Data[0].PlayUrl[0]
      this.MediaServerId = res.Data[0].MediaServerId
      this.ChannelName = res.Data[0].ChannelName
      // console.log("this.getMonitorUrlList======", JSON.stringify(res));
      setTimeout(() => {
        this.newplayer(res.Data[0].PlayUrl[0])
      }, 300)
    },
    newplayer (url) {
      if (flv.isSupported()) {
        // console.log("=-==-=-=this.id=", this.id);
        var videoElement = document.getElementById(this.id)
        // console.log("=-==-=-=videoElement=", videoElement);
        // console.log("=-==-=-=videoElement=", url);
        this.player = flv.createPlayer(
          {
            type: 'flv',
            // isLive: true,
            // url: "http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=10",
            url: url,
            isLive: true,
            cors: true
          },
          {
            enableStashBuffer: false,
            stashInitialSize: 128,
            autoCleanupSourceBuffer: true
          }
        )
        this.player.attachMediaElement(videoElement)
        this.player.load() // 加载
      }
    },
    flv_start () {
      if (!this.player) return
      this.player
        .play()
        .then(() => {
          // 音频加载成功
          // 音频的播放需要耗时
          setTimeout(() => {
            // 后续操作
          }, 1000) // audio.duration 为音频的时长单位为秒
        })
        .catch((e) => {
          // 音频加载失败
        })
    },
    flv_pause () {
      if (!this.player) return
      this.player.pause()
    },
    flv_destroy () {
      if (!this.player) return
      this.resetPlayer()
    },
    initialize () {
      var videoElement = document.getElementById(this.id)
      // console.log("==-===-=this.sources=" + this.sources);
      // console.log("==-===-=id=" + this.id);
      if (videoElement) {
        if (!this.loading) {
          this.loading = true
        }
        if (this.player) {
          this.player.unload()
          this.player.detachMediaElement()
          this.player.destroy()
          this.player = null
        }
        this.newplayer(this.sources)
      }
    },
    resetPlayer () {
      if (!this.player || !this.sources) return
      this.player.pause()
      this.player.unload()
      this.player.detachMediaElement()
      this.player.destroy()
      this.player = null
    },
    click () {
      // if (this.playing) {
      //   this.flv_pause();
      //   this.playing = false;
      // } else {
      //   this.flv_start();
      //   this.playing = true;
      // }
    },
    dblclick () {
      this.requestFullScreen(document.getElementById(this.id))
    },
    onMouseOver () {
      this.timer && clearTimeout(this.timer)
      this.isOver = true
      this.timer = setTimeout(() => {
        this.isOver = false
        this.timer = null
      }, 2000)
    },
    screenshot () {
      // 截屏
      cutpicture('截屏', this.id)
    },
    screenRecording () {
      // 录屏
      // utils.startRecorder(this.id);
    },
    fullScreen () {
      // 全屏展示
      this.requestFullScreen(document.getElementById(this.id))
    },
    async recordScreen () {
      const obj = {
        MediaServerId: this.MediaServerId,
        MainId: this.mainid,
        ChannelName: this.ChannelName
      }
      if (this.recordeFlag) {
        const res = await stopRecord(obj)
        if (res.Code === 200) {
          this.$message.success('录制成功')
          this.recordeFlag = false
        }
      } else {
        obj.ChannelName = this.ChannelName
        const res = await startRecord(obj)
        if (res.Code === 200) {
          this.recordeFlag = true
        }
      }
    },
    // 全屏
    requestFullScreen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    // 退出全屏
    exitFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        docHtml.style.cssText = ''
        docBody.style.cssText = ''
        videobox.style.cssText = ''
        document.IsFullScreen = false
      }
    },
    playing () {
      if (this.loading) {
        this.loading = false
      }
    }
  },
  beforeDestroy () {
    if (!this.recordeFlag) return
    const obj = {
      MediaServerId: this.MediaServerId,
      MainId: this.mainid
    }
    stopRecord(obj)
  },
  created () {
    // console.log("==-===-=created---sources=" + this.sources);
    // console.log("==-===-=created---id=" + this.id);
    // console.log("==-===-=created---realeid=" + this.realeid);
    if (flv.isSupported()) {
      if (typeof this.player !== 'undefined') {
        if (this.player) {
          this.player.unload()
          this.player.detachMediaElement()
          this.player = null
        }
      }
      this.player = flv.createPlayer(
        {
          type: 'flv',
          // isLive: true,
          // url: "http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=10",
          url: this.sources,
          isLive: true,
          cors: true
        },
        {
          enableStashBuffer: false,
          stashInitialSize: 128,
          autoCleanupSourceBuffer: true
        }
      )
    }
  },
  mounted () {
    this.initVedioUrl()
  },
  destroyed () {
    // console.log("==-===-=destroyed=======realeid====" + this.realeid);
    this.resetPlayer()
  }
}
</script>

<style scoped lang="less">
.neiyi {
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 99;

  .loadingBox {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
    background-image: url('~@/assets/img/noMessage/loading.gif');
    background-size: 20% 33%;
    background-position: center center;
    background-repeat: no-repeat;

    .loadingSpan {
      position: absolute;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      bottom: 15%;
    }
  }
}

.zhezhao {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 100;

  .left {
    color: #fff;
  }
  .right{
    display: flex;

    .iconfont{
      color: white;
      font-size: 30px;
      cursor: pointer;
      // margin-right: 10px;
      margin-left: 10px;
    }
    .right-big{
      font-size: 45px!important;
    }
  }

  /deep/ svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-left: 15px;
  }
}

.replacediv {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  background-image: url('~@/assets/img/noMessage/videoDisconnect.png');
  background-size: 20% 45%;
  background-position: center center;
  background-repeat: no-repeat;
}

.recoder {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  color: #d81e06;
  font-size: 25px;
}</style>
